.footer-container {
  background-color: #0c3757; /* Matching the header's background color */
  color: white;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo-img {
  width: 264px;
  height: 164px;
}

.footer-links {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.footer-social-icon {
  fill: white;
  width: 24px;
  height: 24px;
}

.footer-social-icon:hover {
  fill: #f0c040; /* Highlight color */
}

.footer-copyright {
  margin-top: 20px;
}
