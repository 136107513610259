.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 450px;
  width: 100%;
  position: relative;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.modal-logo {
  width: 150px;
  height: auto;
  margin: 0 auto 20px;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.modal-message {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.modal-instructions {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.modal-steps {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
}

.modal-step-item {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.step-number {
  background-color: #007bff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.modal-input {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.modal-submit {
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-submit:hover {
  background-color: #0056b3;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
