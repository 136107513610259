.google-login-button {
  width: 100% !important; /* Ensure consistent width */
  max-width: 100% !important; /* Responsive on small screens */
  padding: 10px !important;
    background-image: linear-gradient(to right, rgb(187 154 25) 10%, rgb(187 154 25)  10%, #f6e671  100%) !important;

  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  color:'black'
  
}
.AuthSocial div{

    background-image: linear-gradient(to right, rgb(187 154 25) 10%, rgb(187 154 25)  10%, #f6e671  100%) !important;

  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  color:'black'
}

.google-login-button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.google-login-button div { /* Sometimes Google's button is wrapped inside a div */
  display: inline-block;
  width: 100% !important;
  text-align: center;
}

.google-login-button img { /* Ensure image icon stays aligned */
  max-width: 20px;
  margin-right: 10px;
}
.google-login-button:disabled {
  background-color: #d3d3d3; /* Gray out the button when disabled */
  cursor: not-allowed;
}

.logout-button {
  background-color: #db4437; /* Logout button color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.userDetails-wrapper {
  text-align: center;
}

.details-wrapper .image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.details-wrapper .name,
.details-wrapper .email {
  margin-top: 10px;
  font-size: 18px;
}
