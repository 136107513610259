/* NewNave.css */
.logo {
  flex-grow: 1;
  text-decoration: none;
}

.logo-img {
  height: 189px;
  width: 307px;
}


.desktop-menu {
  display: none;
}

.nav-link {
  margin-right: 16px;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  color:rgb(218 198 97) !important ;
  z-index: 0;
  
}

.login-button {
  margin-right: 8px;
  border-color: #fff;
  color: #fff;
}

.join-button {
  background-color: #ffffff47;
}

.mobile-menu-button {
  display: none;
}

@media (min-width: 600px) {
  .desktop-menu {
    display: flex;
    align-items: center;
  }

  .mobile-menu-button {
    display: none;
  }
}

@media (max-width: 600px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-button {
    display: flex;
  }
}

.menu-item {
  text-decoration: none;
  color: inherit;
}


.appbar{
  background: linear-gradient(270deg, #f7daa793, rgba(255, 255, 255, 0.6), #f7daa7a1);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease-out infinite; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 50%; 
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 50%; 
  }
  100% {
    background-position: 0% 50%;
  }}