.forex-news-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.forex-news-title {
  text-align: center;
  margin-bottom: 20px;
}

.table-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.forex-news-table {
  width: 80%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hoverable-row:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.learn-more-link {
  margin-top: 20px;
  text-align: center;
}

.learn-more-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.learn-more-link a:hover {
  text-decoration: underline;
}
