.slider-container {
  width: 90%;
  max-width: 1200px; 
  height: 350px; 
  position: relative;
  margin: 0 auto;
}

.slider-image {
  position: relative;
  width: 100%;
  height: 320px; 
  cursor: pointer;
}

.slider-image img {
  width: 100%;
  height: 100%;
  object-fit: fill; 
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}

.content {
  color: #ffffff !important; 
}

.content h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: white !important;
}

.content button {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.3); 
  border: 1px solid rgba(255, 255, 255, 0.5); 
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  backdrop-filter: blur(10px); 
  transition: background 0.3s ease;
}

.content button:hover {
  background: rgba(255, 255, 255, 0.5); 
}

/* Custom arrow styles */
.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}


.slick-prev {
  left: -60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  
}

.slick-next {
  right: -60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
}

.slick-prev:hover {
  left: -60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  
}

.slick-next:hover {
  right: -60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
}

.slick-prev:before, .slick-next:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.slick-prev:before {
  content: '\f053'; /* Font Awesome left arrow icon */
}

.slick-next:before {
  content: '\f054'; /* Font Awesome right arrow icon */
}

/* Responsive styles */
@media (max-width: 768px) {
  .slider-container {
    height: 150px; 
  }

  .slider-image {
    height: 150px;
  }

  .content h2 {
    font-size: 18px;
  }

  .content button {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .slider-container {
    height: 120px; 
  }

  .slider-image {
    height: 120px;
  }

  .content h2 {
    font-size: 16px;
  }

  .content button {
    font-size: 10px;
    padding: 4px 8px;
  }
}


@media (max-width: 1000px) {
.slick-prev {
  left: -10px !important;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  
}

.slick-next {
  right: -10px !important;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
}}