/********** Template CSS **********/
:root {
  --primary: #1363C6;
  --secondary: #15ACE1;
  --light: #F4F7FE;
  --dark: #14183E;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
  font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: normal !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}


/*** Navbar ***/
.sticky-top {
  top: -150px;
  transition: .5s;
}

.navbar {
  padding: 11px 0 !important;
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 35px;
  padding: 0;
  color: rgba(255, 255, 255, .7);
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 1);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
      padding: 0 15px;
      background:#224266 !important ;
  }

  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Hero Header ***/
.hero-header {
  margin-top: -75px;
  background: url(../img/bg-hero.png) center center no-repeat;
  background-size: cover;
}

.hero-header .breadcrumb-item+.breadcrumb-item::before {
  color: var(--light);
}


/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 4px;
  bottom: 0;
  left: 0;
  background: var(--dark);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  bottom: 0;
  left: 50px;
  background: var(--dark);
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: rgba(33, 66, 177, .5);
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}


/*** About ***/
.about-img {
  position: relative;
  overflow: hidden;
 
}

.about-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: url(../img/bg-about-img.png) top left no-repeat; */
  background-size: contain;
}


/*** Service ***/
.service-item {
  position: relative;
  padding: 45px 30px;
  background: #FFFFFF;
  transition: .5s;
 
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  color: var(--primary);
  background: var(--light);
  transition: .5s;
}

.service-item:hover .service-icon {
  background: #FFFFFF;
}

.service-item h5,
.service-item p {
  transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: #FFFFFF;
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: .5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: var(--light);
  transition: .5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}


/*** Feature ***/
.feature {
  background: url(../img/bg-hero.png) center center no-repeat;
  background-size: cover;
}


/*** Newsletter ***/
.newsletter {
  background: url(../img/bg-hero.png) center center no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .newsletter .container {
      max-width: 100% !important;
  }

  .newsletter .newsletter-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .newsletter .newsletter-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .newsletter .newsletter-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Case Study ***/
.case-item img {
  transition: .5s;
}

.case-item:hover img {
  transform: scale(1.2);
}

.case-overlay {
  position: absolute;
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(rgba(20, 24, 62, 0), var(--dark));
  z-index: 1;
}

.case-overlay small {
  display: inline-block;
  padding: 3px 15px;
  color: #FFFFFF;
  background: rgba(20, 24, 62, .7);
  border-radius: 25px;
  margin-bottom: 15px;
}

.case-overlay span.btn:hover {
  color: var(--primary);
  background: #FFFFFF;
  border-color: #FFFFFF;
}


/*** FAQs ***/
.accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.accordion .accordion-button {
  background: var(--light);
  border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
  color: #FFFFFF;
  background: var(--primary);
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
  padding: 15px 0 0 0;
}


/*** Testimonial ***/
.testimonial-carousel {
  position: relative;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 40px;
  height: 100%;
  top: calc(50% - 50px);
  left: -21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 5px 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #FFFFFF;
  border: 1px solid var(--primary);
  border-radius: 40px;
  font-size: 18px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: #FFFFFF;
}

.testimonial-carousel .owl-dots {
  margin-top: 35px;
  margin-left: 3rem;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  background: #FFFFFF;
  border: 1px solid var(--primary);
  border-radius: 15px;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  width: 30px;
  background: var(--primary);
}


/*** Team ***/
.team-item {
  transition: .5s;
  border: 1px solid transparent;
  transition: .5s;
}

.team-item:hover {

  border-color: var(--primary);
}


/*** Footer ***/
.footer {
  background: url(../img/footer.png) center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,.5);
  border: 1px solid rgba(256, 256, 256, .1);
  border-radius: 40px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: flex;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: rgba(255,255,255,.5);
  font-weight: normal;
  transition: .3s;
}

.footer .btn.btn-link:hover {
  color: #FFFFFF;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: rgba(255,255,255,.5);
}

.footer .copyright a:hover {
  color: #FFFFFF;
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}



/*  3 cards  */

.dark {
  background: #110f16;
}

.light {
  background: #f3f5f7;
}

a,
a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
}

/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.postcard.dark {
  background-color: #18151f;
}

.postcard.light {
  background-color: #e1e5ea;
}

.postcard .t-dark {
  color: #18151f;
}

.postcard a {
  color: inherit;
}

.postcard h1,
.postcard .h1 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.postcard .small {
  font-size: 80%;
}

.postcard .postcard__title {
  font-size: 1.75rem;
}

.postcard .postcard__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.postcard .postcard__img_link {
  display: contents;
}

.postcard .postcard__bar {
  width: 50px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.2s ease;
}

.postcard .postcard__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.postcard .postcard__preview-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  height: 100%;
}

.postcard .postcard__tagbox {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0;
  justify-content: center;
}

.postcard .postcard__tagbox .tag__item {
  display: inline-block;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 3px;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}

.postcard .postcard__tagbox .tag__item:hover {
  background: rgba(83, 83, 83, 0.8);
}

.postcard:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 1;
  border-radius: 10px;
}

.postcard:hover .postcard__bar {
  width: 100px;
}

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }
  
  .postcard .postcard__title {
    font-size: 2rem;
  }
  
  .postcard .postcard__tagbox {
    justify-content: start;
  }
  
  .postcard .postcard__img {
    max-width: 300px;
    max-height: 100%;
    transition: transform 0.3s ease;
  }
  
  .postcard .postcard__text {
    padding: 3rem;
    width: 100%;
  }
  
  .media.postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }
  
  .postcard:hover .postcard__img {
    transform: scale(1.1);
  }
  
  .postcard:nth-child(2n+1) {
    flex-direction: row;
  }
  
  .postcard:nth-child(2n+0) {
    flex-direction: row-reverse;
  }
  
  .postcard:nth-child(2n+1) .postcard__text::before {
    left: -12px !important;
    transform: rotate(4deg);
  }
  
  .postcard:nth-child(2n+0) .postcard__text::before {
    right: -12px !important;
    transform: rotate(-4deg);
  }
}

@media screen and (min-width: 1024px) {
  .postcard .postcard__text {
    padding: 2rem 3.5rem;
  }
  
  .postcard .postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }
  
  .postcard.dark .postcard__text:before {
    background: #18151f;
  }
  
  .postcard.light .postcard__text:before {
    background: #e1e5ea;
  }
}

/* COLORS */
.postcard .postcard__tagbox .green.play:hover {
  background: var(--main-green);
  color: black;
}

.green .postcard__title:hover {
  color: var(--main-green);
}

.green .postcard__bar {
  background-color: var(--main-green);
}

.green::before {
  background-image: linear-gradient(-30deg, var(--main-green-rgb-015), transparent 50%);
}

.green:nth-child(2n)::before {
  background-image: linear-gradient(30deg, var(--main-green-rgb-015), transparent 50%);
}

.postcard .postcard__tagbox .blue.play:hover {
  background: var(--main-blue);
}

.blue .postcard__title:hover {
  color: var(--main-blue);
}

.blue .postcard__bar {
  background-color: var(--main-blue);
}

.blue::before {
  background-image: linear-gradient(-30deg, var(--main-blue-rgb-015), transparent 50%);
}

.blue:nth-child(2n)::before {
  background-image: linear-gradient(30deg, var(--main-blue-rgb-015), transparent 50%);
}

.postcard .postcard__tagbox .red.play:hover {
  background: var(--main-red);
}

.red .postcard__title:hover {
  color: var(--main-red);
}

.red .postcard__bar {
  background-color: var(--main-red);
}

.red::before {
  background-image: linear-gradient(-30deg, var(--main-red-rgb-015), transparent 50%);
}

.red:nth-child(2n)::before {
  background-image: linear-gradient(30deg, var(--main-red-rgb-015), transparent 50%);
}

.postcard .postcard__tagbox .yellow.play:hover {
  background: var(--main-yellow);
  color: black;
}

.yellow .postcard__title:hover {
  color: var(--main-yellow);
}

.yellow .postcard__bar {
  background-color: var(--main-yellow);
}

.yellow::before {
  background-image: linear-gradient(-30deg, var(--main-yellow-rgb-015), transparent 50%);
}

.yellow:nth-child(2n)::before {
  background-image: linear-gradient(30deg, var(--main-yellow-rgb-015), transparent 50%);
}

@media screen and (min-width: 769px) {
  .green::before {
    background-image: linear-gradient(-80deg, var(--main-green-rgb-015), transparent 50%);
  }
  
  .green:nth-child(2n)::before {
    background-image: linear-gradient(80deg, var(--main-green-rgb-015), transparent 50%);
  }
  
  .blue::before {
    background-image: linear-gradient(-80deg, var(--main-blue-rgb-015), transparent 50%);
  }
  
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(80deg, var(--main-blue-rgb-015), transparent 50%);
  }
  
  .red::before {
    background-image: linear-gradient(-80deg, var(--main-red-rgb-015), transparent 50%);
  }
  
  .red:nth-child(2n)::before {
    background-image: linear-gradient(80deg, var(--main-red-rgb-015), transparent 50%);
  }
  
  .yellow::before {
    background-image: linear-gradient(-80deg, var(--main-yellow-rgb-015), transparent 50%);
  }
  
  .yellow:nth-child(2n)::before {
    background-image: linear-gradient(80deg, var(--main-yellow-rgb-015), transparent 50%);
  }


  .animated-img {
    animation: moveImage 5s infinite alternate ease-in-out ;
}

@keyframes moveImage {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(20px);
    }
}


}


.hovera:hover{
border-bottom: 4px solid #224266;
transition: 0.3s;
color: #224266;
}



#aboutUsTitle{
  font-size: 37px;

}

body{
  background-color: white !important;
  background-image: url(../../Home/img/perk_side_cover.svg) ;
  background-position: right;
  background-repeat: no-repeat;
  
   
 
}

#aboutUsLabel,#servicesBtn{
font-size: 22px;
font-weight: 900;
}
#aboutUsLabel{
  padding-left: 0 !important;
}

#awardWinning,#professionalStaff,#twentyFourSevenSupport,#fairPrices{
  font-size: 20px;
}

.arrow{
background-color: #dec75d !important;
border: none !important;

}

#exploreServicesTitle{
font-size: 40px;
}

#whyUsBtn{
  font-size: 40px;
  font-weight: 900;
  padding-left: 0 !important;
}


.animated-section {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
}



@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}



#root > div.App > div:nth-child(1) > header > div > div > button{
  background-color: #dbc657 !important;
}



.responsive-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
}

@media (max-width: 961px) {
  .responsive-video {
    padding-bottom: 56.25%; /* Keep the 16:9 aspect ratio for smaller screens */
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
/* For Webkit browsers (Chrome, Safari) */
.modal-body::-webkit-scrollbar {
  width: 12px;
}

.modal-body::-webkit-scrollbar-track {
  background: white;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #234264;
  border-radius: 20px;
  border: 3px solid white;
}

.disclamer{
font-size: 15px;
color:#dbc657;
}



/* body {
  background: linear-gradient(300deg,white,#ffffff,#dbc55734);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
} */

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.modern-tabs .nav-link {
  display: inline-block;
  color: #234264; /* Customize the color */
  background: #f8f9fa;
  border-radius: 35px;
  padding: 10px 20px;
  margin: 0 30px;
  transition: background 0.3s ease, color 0.3s ease;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.modern-tabs .nav-link .tab-icon {
  margin-right: 8px;
}

.modern-tabs .nav-link.active {

  color: white;
}

.modern-tabs .nav-link:hover {
 
  color: white;
}

.container {
  max-width: 1000px;
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.text-container {
  flex: 1;
}

.image-container {
  flex: 1;
  
}

.service-image {
  width: 500px; 
  height: 400px !important;
  object-fit: fill; 
  margin-left: auto;
  
}



.big-text {
  font-size: 20px; 
  font-weight: bold;
  line-height: 2.1;
}
.hovera{
font-size: 26px;
color:#dbc657;
font-weight: bolder;
}
.discoverbtn{
background-color: #234264 !important;
border: unset !important;
}



@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.why {
  /* animation: spin 14s linear infinite; */
}
.whycenter{
  display: flex;
  justify-content: center;
}






@media (max-width: 961px) {
  .parent{
    flex-direction: column !important;
  }

}




.imghero{
max-width:80%;
min-width: 80%;
max-height: 500px;
min-height: 500px;
}





.nav-link {
  position: relative;
  border: none; /* Remove border */
  background: none; /* Remove background change */
  color: #555; /* Default color */
  padding: 10px 15px;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #000; /* Change color on hover */
}

.underline-tab::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #007bff; /* Underline color */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.underline-tab {
  color: #1363c6 !important; /* Active tab color */
}




.containerceo {
  max-width: 1200px; /* Adjust as necessary for your layout */
  margin: -70px auto; /* Center the container */
  padding: 20px; /* Add padding around the container */
}

.ceo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9; /* Light background for contrast */
  padding: 70px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative; /* For the pseudo-element border */
  overflow: hidden; /* Hide overflow for rounded corners */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ceo-section {
    flex-direction: column; /* Change to column layout on mobile */
    align-items: center; /* Center align items */
    padding: 20px; /* Reduce padding for mobile */
  }

  .ceo-info {
    max-width: 100%; /* Allow full width for text on mobile */
    text-align: center; /* Center text */
  }

  .ceo-info h5 {
    font-size: 1.2rem; /* Adjust size for mobile */
  }

  .ceo-info h4 {
    font-size: 1.5rem; /* Adjust size for mobile */
  }

  .ceo-info h2 {
    font-size: 2rem; /* Adjust size for mobile */
  }

  .ceo-info p {
    font-size: 0.9rem; /* Adjust size for mobile */
    margin: 10px 0; /* Adjust margin for mobile */
  }

  .ceo-image img {
    width: 80%; /* Make image responsive */
    max-width: 300px; /* Limit max width for larger screens */
    height: auto; /* Maintain aspect ratio */
  }
}

.ceo-section::before {
  content: '';
  position: absolute;
  top: -5px; /* Adjusts the position */
  left: -5px; /* Adjusts the position */
  right: -5px; /* Adjusts the position */
  bottom: -5px; /* Adjusts the position */
  background: linear-gradient(45deg, #ff6b6b, #f6b93b, #4caf50, #2196f3, #673ab7);
  background-size: 300% 300%; /* Allow for animated background */
  border-radius: 15px; /* Rounded corners */
  z-index: -1; /* Places it behind the section */
  animation: borderAnimation 5s ease infinite; /* Animation for the border */
}

@keyframes borderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ceo-info {
  max-width: 50%;
}

/* Responsive styles for headings and paragraphs */
.ceo-info h5 {
  font-size: 1.5rem; /* Adjust size as necessary */
}

.ceo-info h4 {
  font-size: 1.8rem; /* Adjust size as necessary */
}

.ceo-info h2 {
  font-size: 2.5rem;
  color: #3F485E; /* Darker color for text */
  margin: 10px 0;
}

.ceo-info p {
  color: #77838F; /* Grayish color for description */
  margin: 15px 0;
  line-height: 1.6;
}

.talk-button {
  padding: 10px 20px;
  background-color: #234264; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.talk-button:hover {
  background-color: #1b3a5b; /* Darker shade on hover */
}

.ceo-image {
  position: relative;
  overflow: hidden;
}

.ceo-image img {
  width: 300px; /* Adjust width as necessary */
  border-radius: 50%; /* Circular image */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: smoothInfinite 5s linear infinite; /* Infinite smooth animation */
}

@keyframes smoothInfinite {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Slightly enlarge the image */
  }
  100% {
    transform: scale(1);
  }
}


.nav{
  border-bottom: 1px solid #00000038 !important;
}


@media (max-width: 768px) {
  .ceo-info {
      max-width: 100%;
      text-align: center;
  }
}

.paddingcustom{
  padding-top: 50px;
  padding-bottom: 50px;
}


.gradient-custom {
  background: radial-gradient(circle, #dec75d 0%, #244262 100%);
  background-size: 400% 400%; 
  animation: waveBackground 15s ease infinite; 
}

@keyframes waveBackground {
  0% {
    background-position: 0% 50%; 
  }
  50% {
    background-position: 100% 50%; 
  }
  100% {
    background-position: 0% 50%; 
  }
}

.carousel-indicators {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

body {
  overflow-x: hidden !important; 
}
.container {
  overflow: hidden !important;
}
#footer{
  overflow: hidden !important;
}

.about-us-title {
  transition: color 0.3s ease, transform 0.3s ease;
}

.about-us-title:hover {
  color: #23426477; 
  transform: scale(1.1); 
}


/* For Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #dec75d;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dec75d #f1f1f1;
}


.profile-heading {
  text-align: center;
  margin-top: 50px;
}
.profile-heading h3 {
  color: gold;
  font-size: 2em;
  margin-bottom: 10px;
}
.profile-heading p {
  color: #3F485E; /* Dark grey */
  font-size: 1.2em;
  margin-top: 0;
}


/* Pause the animation on hover */
.animated-img {
  animation-play-state: running !important;
  transition: all 0.3s ease !important;
}

.animated-img:hover {
  animation-play-state: paused !important; /* Pause the animation */
}

.parent {
  transition: all 0.3s ease !important;
}

.parent:hover .animated-img {
  animation-play-state: paused !important;
}
